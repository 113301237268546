import React, { useState } from "react"
import Layout from "components/layout"

import PageLead from "../../components/pageLead";
import SelectContactType from "../../components/contact/selectContactType";
import ServiceForm from "../../components/contact/service/form";
import ServiceConfirm from "../../components/contact/service/confirm";
import ContactComplete from "../../components/contact/complete";
import PrForm from "../../components/contact/pr/form";
import PrConfirm from "../../components/contact/pr/confirm";
import OtherForm from "../../components/contact/other/form";
import OtherConfirm from "../../components/contact/other/confirm";
import OpenForm from "../../components/contact/open/form";
import OpenConfirm from "../../components/contact/open/confirm";
import PropertyConfirm from "../../components/contact/property/confirm";
import PropertyForm from "../../components/contact/property/form";
import Seo from "../../components/seo";
import OpForm from "../../components/contact/op/form";
import OpConfirm from "../../components/contact/op/confirm";
import OwnersSupportForm from "../../components/contact/owners-support/form";
import OwnersSupportConfirm from "../../components/contact/owners-support/confirm";
import InitBlankPage from "../../components/contact/initBlankPage";

const ContactPage = () => {
  const [contactState, setContactState] = useState({
    selectedType: "init",
    step: 'form',
  })
  const [formData, setFormData] = useState({
    comment: '',
    shop_name: '',
    company_name: '',
    last_name: '',
    first_name: '',
    last_name_ruby: '',
    first_name_ruby: '',
    email: '',
    tel: '',
    contact_type: '',
    media_name: '',
    published_issue: '',
    release_date: '',
    plan_name: '',
    plan_volume: '',
    plan_contents: '',
    interview_from_date: '',
    interview_to_date: '',
    section_name: '',
    has_experience: '',
    has_property: '',
    opening_date: '',
    area: '',
    business_form: '',
    industry: '',
    property_type: ''
  })
  const handleOnSelect = e => {
    setContactState({
      ...contactState,
      selectedType: e.target.value,
    })
  }

  const handleOnNextPage = data => {
    setFormData({
      ...formData,
      comment: data.comment,
      shop_name: data.shop_name ?? "",
      company_name: data.company_name ?? "",
      last_name: data.last_name,
      first_name: data.first_name,
      last_name_ruby: data.last_name_ruby,
      first_name_ruby: data.first_name_ruby,
      email: data.email,
      tel: data.tel ?? "",
      contact_type: data.contact_type ?? "",
      media_name: data.media_name ?? "",
      published_issue: data.published_issue ?? "",
      release_date: data.release_date ?? "",
      plan_name: data.plan_name ?? "",
      plan_volume: data.plan_volume ?? "",
      plan_contents: data.plan_contents ?? "",
      interview_from_date: data.interview_from_date ?? "",
      interview_to_date: data.interview_to_date ?? "",
      section_name: data.section_name ?? "",
      has_experience: data.has_experience ?? "",
      has_property: data.has_property ?? "",
      opening_date: data.opening_date ?? "",
      area: data.area ?? "",
      business_form: data.business_form ?? "",
      industry: data.industry ?? "",
      property_type: data.property_type ?? "",
      requirement: data.requirement ?? ""
    });

    let nextStep = 'form';
    if (contactState.step === 'form'){
      nextStep = 'confirm';
    }else if (contactState.step === 'confirm'){
      nextStep = 'complete';
    }

    setContactState({
      ...contactState,
      step: nextStep,
    })
  }
  const handleOnBackPage = () => {
    let prevStep = 'confirm';
    if (contactState.step === 'confirm'){
      prevStep = 'form';
    }
    setContactState({
      ...contactState,
      step: prevStep,
    })
  }

  return (
    <Layout>
      <div className="contact-page">
        <PageLead sub="CONTACT"
                  title="お問い合わせ"
        />
        <div className="contents">
          <SelectContactType
            handleOnSelect={handleOnSelect}
            contactState={contactState}
          />
          <InitBlankPage
              contactState={contactState}
          />
          <ServiceForm
              contactState={contactState}
              handleOnNextPage={handleOnNextPage}
          />
          <ServiceConfirm
              contactState={contactState}
              handleOnNextPage={handleOnNextPage}
              handleOnBackPage={handleOnBackPage}
              formData={formData}
          />
          <PrForm
              contactState={contactState}
              handleOnNextPage={handleOnNextPage}
          />
          <PrConfirm
              contactState={contactState}
              handleOnNextPage={handleOnNextPage}
              handleOnBackPage={handleOnBackPage}
              formData={formData}
          />
          <OpenForm
              contactState={contactState}
              handleOnNextPage={handleOnNextPage}
          />
          <OpenConfirm
              contactState={contactState}
              handleOnNextPage={handleOnNextPage}
              handleOnBackPage={handleOnBackPage}
              formData={formData}
          />
          <PropertyForm
              contactState={contactState}
              handleOnNextPage={handleOnNextPage}
          />
          <PropertyConfirm
              contactState={contactState}
              handleOnNextPage={handleOnNextPage}
              handleOnBackPage={handleOnBackPage}
              formData={formData}
          />
          <OpForm
              contactState={contactState}
              handleOnNextPage={handleOnNextPage}
          />
          <OpConfirm
              contactState={contactState}
              handleOnNextPage={handleOnNextPage}
              handleOnBackPage={handleOnBackPage}
              formData={formData}
          />
          <OtherForm
              contactState={contactState}
              handleOnNextPage={handleOnNextPage}
          />
          <OtherConfirm
              contactState={contactState}
              handleOnNextPage={handleOnNextPage}
              handleOnBackPage={handleOnBackPage}
              formData={formData}
          />
          <ContactComplete
              contactState={contactState}
          />
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => (
    <Seo
        title="お問い合わせ"
        description="OKULAB（オクラボ）のお問い合わせページです。"
    />
)
export default ContactPage
