import React, { useState } from "react"
import { useForm } from "react-hook-form"

const OwnersSupportForm = props => {
    const [ agreementState, setAgreementState] = useState(false)
    const { contactState, handleOnNextPage } = props
    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
    } = useForm();

    const handleOnAgreementChecked = () => {
        setAgreementState(!agreementState);
    }

    const onSubmit = data => {
        handleOnNextPage(data);
    }

    if (contactState.step === 'form') {
        return (
            <section className="form-wrap">
                <h3>定期巡回についてのお問い合わせ</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="column">
                        <div className="name">
                            お問い合わせ内容
                            <div className="notification">※800字以下で入力してください。</div>
                        </div>
                        <div className="form-control-text">
                                    <textarea name="comment"
                                              maxLength="800"
                                              rows={16}
                                              placeholder="例）サービスの資料請求"
                                              {...register("comment")}
                                    ></textarea>
                        </div>
                    </div>
                    <div className="column">
                        <div className="name">
                            会社名
                        </div>
                        <div className="form-control-text">
                            <input type="text"
                                   name="company_name"
                                   className="text-half"
                                   {...register("company_name")}
                            />
                        </div>
                    </div>
                    <div className="column">
                        <div className="name">
                            部署名
                        </div>
                        <div className="form-control-text">
                            <input type="text"
                                   name="section_name"
                                   className="text-half"
                                   {...register("section_name")}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="column">
                            <div className="name">
                                ご担当者様（漢字）
                            </div>
                            <div className="form-control-text">
                                <input type="text"
                                       name="last_name"
                                       placeholder="姓"
                                       className={`${errors.last_name ? 'error' : ''} text-half`}
                                       {...register("last_name")}
                                />
                                <input type="text"
                                       name="first_name"
                                       placeholder="名"
                                       className={`${errors.first_name ? 'error' : ''} text-half`}
                                       {...register("first_name")}
                                />
                            </div>
                        </div>
                        <div className="column">
                            <div className="name">
                                ご担当者様（カナ）
                            </div>
                            <div className="form-control-text">
                                <input type="text"
                                       name="last_name_ruby"
                                       placeholder="セイ"
                                       className={`${errors.last_name_ruby ? 'error' : ''} text-half`}
                                       {...register("last_name_ruby")}
                                />
                                <input type="text"
                                       name="first_name_ruby"
                                       placeholder="メイ"
                                       className={`${errors.first_name_ruby ? 'error' : ''} text-half`}
                                       {...register("first_name_ruby")}
                                />
                            </div>
                        </div>
                        <div className="column">
                            <div className="name">
                                メールアドレス<span className="required">必 須</span>
                            </div>
                            <div className="form-control-text">
                                <input type="email"
                                       name="email"
                                       className={`${errors.email ? 'error' : ''}`}
                                       {...register("email", {required: true})}/>
                                {errors.email && (
                                    <span className="error-message">メールアドレスを入力してください。</span>
                                )}
                            </div>
                        </div>
                        <div className="column">
                            <div className="name">
                                メールアドレス（確認）<span className="required">必 須</span>
                            </div>
                            <div className="form-control-text">
                                <input type="email"
                                       name="email_confirm"
                                       className={`${errors.email_confirm ? 'error' : ''}`}
                                       {...register("email_confirm", {
                                           validate: value => {
                                               return (
                                                   value === getValues("email") ||
                                                   "メールアドレス（確認 ）が一致しません"
                                               )
                                           },
                                       })}
                                />
                                {errors.email_confirm &&
                                    errors.email_confirm.type === "validate" && (
                                        <span className="error-message">{errors.email_confirm.message}</span>
                                    )
                                }
                            </div>
                        </div>
                        <div className="column">
                            <div className="name">
                                電話番号
                            </div>
                            <div className="form-control-text">
                                <input type="tel"
                                       name="tel"
                                       {...register("tel")}
                                />
                            </div>
                        </div>
                        <div className="column">
                            <div className="name">
                                個人情報の取り扱いへの同意確認
                            </div>
                            <div className="form-control-check">
                                <div>
                                    <a href="/policy" target="_blank" className="link-text">
                                        個人情報のお取り扱いに関する同意事項
                                        <img
                                            src="https://stgokulabweb.blob.core.windows.net/images/alliance/icon-open-link.svg"
                                            alt="Open Link"/>
                                    </a>
                                </div>
                                <input type="checkbox"
                                       id="is_agreement_policy"
                                       name="is_agreement_policy"
                                       checked={agreementState}
                                       onChange={() => handleOnAgreementChecked()}
                                       required/>
                                <label htmlFor="is_agreement_policy"
                                       className="checkbox">個人情報のお取り扱いに関する同意事項を読み、内容に同意します。</label>
                            </div>
                        </div>
                    </div>
                    <div className="button-wrap">
                        <button type="submit" disabled={!agreementState}>
                            送信確認
                            <span className="bracket"></span>
                        </button>
                    </div>
                </form>
            </section>
        )
    }
}

export default OwnersSupportForm
