import React from "react"

const SelectContactType = props => {
  const { handleOnSelect, contactState } = props
  if (contactState.step === 'form') {
    return (
        <div className="select-contact-type-wrap">
          <label htmlFor="select-contact-type">お問い合わせ種別</label>
          <select id="select-contact-type" onChange={handleOnSelect}>
              <optgroup label="">
                <option value="init">お問い合わせ種別を選んでください</option>
                <option value="service">サービスに関するご意見・お問い合わせ</option>
                <option value="pr">PR・メディア関係者様のお問い合わせ</option>
                <option value="open">ランドリー出店・FC加盟について</option>
                <option value="property">物件のご紹介</option>
                <option value="op">コインランドリー運営代行について</option>
                <option value="other">その他のお問い合わせ</option>
              </optgroup>
          </select>
        </div>
    );
  }else{
    return ""
  }
}

export default SelectContactType
